<template>
  <div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(imgUrlDisplay)="{ item }">
        <img class="render-image" :src="item.imgUrlDisplay" />
      </template>

      <template #cell(mobileImgUrlDisplay)="{ item }">
        <img class="render-image" :src="item.mobileImgUrlDisplay" />
      </template>

      <template #cell(isDisplay)="{ item }">
        <p
          class="m-0"
          :class="item.isDisplay == true ? 'color-green' : 'color-red'"
        >
          {{ item.isDisplay == true ? "Active" : "Inactive" }}
        </p>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <font-awesome-icon
          class="mr-4 pointer"
          icon="pen"
          @click="editBanner(item)"
        />
        <font-awesome-icon
          class="pointer"
          icon="trash-alt"
          @click="removeBanner(item)"
        />
      </template>

      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  methods: {
    editBanner(obj) {
      this.$emit("editBanner", obj);
    },
    removeBanner(obj) {
      this.$emit("removeBanner", obj);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}

.color-red {
  color: #dc3545;
}

.color-green {
  color: #28a745;
}

.color-blue {
  color: lightcoral;
}

.text-time {
  opacity: 0.5;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.render-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 160px;
  min-height: 160px;
  margin: auto;
}
</style>
