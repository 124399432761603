<template>
  <div class="banner">
    <b-row>
      <b-col cols="4">
        <p class="text-header">Banner</p>
      </b-col>
      <b-col cols="8">
        <div class="d-flex justify-content-end">
          <b-button class="btn-filter">
            <span class="d-none d-md-block" @click="openSidebarBanner">
              <span class="">Create</span>
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <SideBarFilter
      :filter="form"
      ref="sidebarBanner"
      placeholder="Transaction"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      title="Banner"
      :textSubmit="form?.id ? 'Update' : 'Create'"
      @searchAll="onAddBanner"
      handleSideBar
      @clearForm="clearForm"
      hideFilter
      :isLoading="isLoading"
      @clearLoading="isLoading = false"
    >
      <template v-slot:filter-option>
        <div class="my-3">
          <UploadFile
            textFloat="Image Destop"
            placeholder="Please Choose File"
            format="image"
            name="file"
            text="* ขนาดรูปภาพที่แนะนำ 2000 * 900"
            accept="image/*"
            id="uploadfile"
            required="Please select image."
            isRequired
            @onFileChange="onFileChange($event, 1)"
            :fileName="form.imgUrl"
            v-model="form.imgUpload.base64"
            :v="$v.form.imgUpload.base64"
          />
        </div>
        <div
          class="render-image"
          :style="`background-image:url(${form.imgUpload.base64})`"
        ></div>
        <div class="my-3" v-if="$isEventGroup === false">
          <UploadFile
            textFloat="Image Mobile"
            placeholder="Please Choose File"
            format="image"
            name="file"
            text="* ขนาดรูปภาพที่แนะนำ 900 * 900"
            accept="image/*"
            id="uploadfile"
            required="Please select image."
            isRequired
            @onFileChange="onFileChange($event, 2)"
            :fileName="form.mobileImgUrl"
            v-model="form.mobileImgUpload.base64"
            :v="$v.form.mobileImgUpload.base64"
          />
        </div>
        <div
          v-if="$isEventGroup === false"
          class="render-image"
          :style="`background-image:url(${form.mobileImgUpload.base64})`"
        ></div>
        <div class="my-3">
          <div>
            <div class="font-weight-bold mb-2">
              Start Date (DD/MM/YYYY)
              <span class="text-error">*</span>
            </div>
            <div
              class="input-container"
              :class="$v.form.startTimeDisplay.$error && 'border-red'"
            >
              <datetime
                v-model="form.startTimeDisplay"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="createStartDate"
              >
                <!-- v-model="configFilter.createdTime.endTime"
                  :disabled="!configFilter.createdTime.startTime"
                  :max-datetime="maxDate"
                  :min-datetime="minDateCreate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.createStartDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="m-0 text-error" v-if="$v.form.startTimeDisplay.$error">
              Please select date
            </p>
          </div>
        </div>
        <div class="my-3">
          <div>
            <div class="font-weight-bold mb-2">
              End Date (DD/MM/YYYY)
              <span class="text-error">*</span>
            </div>
            <div
              class="input-container"
              :class="$v.form.endTimeDisplay.$error && 'border-red'"
            >
              <datetime
                v-model="form.endTimeDisplay"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="createEndDate"
                :min-datetime="form.startTimeDisplay"
              >
                <!-- v-model="configFilter.createdTime.endTime"
                  :disabled="!configFilter.createdTime.startTime"
               
                  :min-datetime="minDateCreate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.createEndDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="m-0 text-error" v-if="$v.form.endTimeDisplay.$error">
              Please select date
            </p>
          </div>
        </div>
        <div class="my-3">
          <InputText
            v-model="form.sortOrder"
            class="input"
            textFloat="Sort Order"
            placeholder="0"
            type="number"
            name="number"
            isRequired
            :isValidate="$v.form.sortOrder.$error"
            :v="$v.form.sortOrder"
          />
        </div>
        <div class="my-3">
          <InputSelect
            v-model="form.bannerTypeId"
            title="Banner Type"
            name="type"
            isRequired
            class="mt-2"
            :options="optionBannerType"
            valueField="id"
            textField="name"
            @onDataChange="($event) => (form.bannerTypeId = $event)"
            :isValidate="$v.form.bannerTypeId.$error"
            :v="$v.form.bannerTypeId"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >-- Select Category --
              </b-form-select-option>
            </template>
          </InputSelect>
        </div>
        <div class="my-3">
          <InputText
            v-model="form.url"
            class="input"
            textFloat="External link"
            placeholder="External link"
            type="text"
            name="Externallink"
            :isRequired="form.bannerTypeId == 6"
            :disabled="form.bannerTypeId != 6"
            :isValidate="$v.form.url.$error"
            :v="$v.form.url"
          />
        </div>
        <div class="my-3">
          <b-form-checkbox
            v-model="form.isDisplay"
            switch
            :value="true"
            :unchecked-value="false"
          >
            Active
          </b-form-checkbox>
        </div>
      </template>
    </SideBarFilter>
    <Table
      :items="items"
      :fields="fields"
      :isBusy="isBusy"
      :rows="rows"
      :filter="filter"
      :pageOptions="pageOptions"
      @filterPage="filterPage"
      @editBanner="editBanner"
      @removeBanner="openModalConfirm"
    />
    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
    <ModalConfirmDelete :nameItem="nameItem" @confirm="confirm" />
  </div>
</template>

<script>
import Table from "./component/table.vue";
import UploadFile from "@/components/inputs/UploadFile";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import ModalConfirmDelete from "@/components/modal/ModalConfirmDelete.vue";

export default {
  components: {
    Table,
    UploadFile,
    ModalAlertText,
    ModalConfirmDelete,
  },
  data() {
    return {
      // state
      form: {
        bannerTypeId: null,
        contentTypeId: 0,
        contentRefId: 0,
        targetUrlId: 0,
        url: "",
        mobileImgUrl: "",
        imgUrl: "",
        imgUpload: {
          base64: "",
        },
        mobileImgUpload: {
          base64: "",
        },
        isVideo: false,
        sortOrder: null,
        isDisplay: true,
        startTimeDisplay: null,
        endTimeDisplay: null,
      },

      // state option

      optionBannerType: [],

      // state table
      items: [],

      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 15, text: "15 / page" },
        { value: 20, text: "20 / page" },
      ],

      filter: {
        filter: null,
        keyword: "",
        skip: 1,
        take: 5,
        page: 1,
      },

      isLoading: false,

      // state datetime
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      // state toggle
      iconSearch: false,

      // state modal
      textModal: "",
      arrStatus: [],

      // state del
      nameItem: "",
      dataRemove: {},
    };
  },
  validations() {
    return {
      form: {
        imgUpload: {
          base64: { required },
        },
        mobileImgUpload: {
          base64: requiredIf(() => {
            return this.$isEventGroup !== true;
          }),
        },
        startTimeDisplay: { required },
        endTimeDisplay: { required },
        sortOrder: { required, minValue: minValue(1) },
        bannerTypeId: { required },
        url: {
          required: requiredIf(() => {
            return this.form.bannerTypeId == 6;
          }),
        },
      },
    };
  },
  watch: {
    "form.bannerTypeId"(value) {
      if (value != 6) {
        this.form.url = "";
      }
    },
  },
  mounted() {
    this.getBanner();
  },
  computed: {
    fields() {
      let fields = [
        {
          key: "imgUrlDisplay",
          label: "Image Destop",
          class: "w-100px text-nowrap",
        },

        {
          key: "sortOrder",
          label: "Sort Order",
          class: "w-50px text-nowrap",
        },
        {
          key: "isDisplay",
          label: "Status",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ];
      if (this.$isEventGroup !== true) {
        fields.splice(1, 0, {
          key: "mobileImgUrlDisplay",
          label: "Image Mobile",
          class: "w-50px text-nowrap",
        });
      }
      return fields;
    },
  },
  methods: {
    async getBanner() {
      this.isBusy = true;

      const getData = await this.$services.banner.getBanner(
        this.filter.skip,
        this.filter.take
      );

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }

      this.isBusy = false;
    },
    async getOptionBannerType() {
      const getData = await this.$services.master.getOptionBannerType();

      if (getData.result == 1) {
        this.optionBannerType = getData.detail;
      }
    },
    async onAddBanner() {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      if (this.form.id) {
        // edit
        const finalBody = {
          ...this.form,
          contentRefId: Number(this.form.contentRefId),

          imgUpload: this.form.imgUpload.base64.includes("https")
            ? null
            : {
                base64: this.form.imgUpload.base64,
              },
          mobileImgUpload: this.form.mobileImgUpload.base64.includes("https")
            ? null
            : {
                base64: this.form.mobileImgUpload.base64,
              },
        };

        this.onEditBanner(finalBody);
      } else {
        // create
        this.createBanner();
      }
    },

    async createBanner() {
      this.isLoading = true;

      const create = await this.$services.banner.manageBanner(0, this.form);

      if (create.result == 1) {
        this.getBanner();
      }

      this.isLoading = false;
      this.openModalAlertText(create.detail.message);
      this.closeSidebarBanner();
    },

    async onEditBanner(body) {
      this.isLoading = true;

      const create = await this.$services.banner.manageBanner(body.id, body);

      if (create.result == 1) {
        this.getBanner();
      }

      this.isLoading = false;
      this.openModalAlertText(create.detail.message);
      this.closeSidebarBanner();
    },

    editBanner(obj) {
      let finalForm = { ...this.form, ...obj };

      finalForm.imgUpload.base64 = obj.imgUrlDisplay;
      finalForm.mobileImgUpload.base64 = obj.mobileImgUrlDisplay;

      this.form = finalForm;
      this.openSidebarBanner();
    },

    async confirm() {
      this.isLoading = true;

      const del = await this.$services.banner.deleteBanner(this.dataRemove.id);

      if (del.result == 1) {
        this.getBanner();
      }

      this.isLoading = true;
      this.openModalAlertText(del.detail || del.message);
      this.closeModalConfirm();
    },

    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },

    openModalConfirm(data) {
      this.dataRemove = data;
      this.$bvModal.show("modal-confirm-delete");
    },

    closeModalConfirm() {
      this.$bvModal.hide("modal-confirm-delete");
    },

    filterPage(obj) {
      let { page } = obj;
      this.filter.skip = page;

      this.getBanner();
    },

    clearForm() {
      this.form = {
        bannerTypeId: null,
        contentTypeId: 0,
        contentRefId: 0,
        targetUrlId: 0,
        url: "",
        mobileImgUrl: "",
        imgUrl: "",
        imgUpload: {
          base64: "",
        },
        mobileImgUpload: {
          base64: "",
        },
        isVideo: false,
        sortOrder: null,
        isDisplay: true,
        startTimeDisplay: null,
        endTimeDisplay: null,
      };
      this.$v.form.$reset();
    },
    onFileChange(file, type) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (result) => {
        if (type == 1) {
          this.form.imgUrl = file.name;
          this.form.imgUpload.base64 = result.target.result;
        } else {
          this.form.mobileImgUrl = file.name;
          this.form.mobileImgUpload.base64 = result.target.result;
        }
      };
    },
    openSidebarBanner() {
      this.getOptionBannerType();
      this.$refs.sidebarBanner.show();
    },
    closeSidebarBanner() {
      this.$refs.sidebarBanner.hide();
    },
    searchAll(filter) {},
    clearFilter(filter) {},
  },
};
</script>

<style lang="scss" scoped>
.banner {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
  .div-divide {
    border-bottom: 1px solid darkgray;
    padding: 10px 0px;
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .render-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 200px;
    border: 1px dashed gray;
  }
}
.text-error {
  margin: 0;
  color: red;
}
// ! override
.g-form {
  width: 100%;
}

.border-red {
  border-color: red;
}

::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
  min-width: 80px;
}
</style>
